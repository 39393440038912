.logoProducts {
    width: 30%;
}

.productHeader {
    flex: 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.productBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    flex: 4;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
    overflow: scroll;
}

.productProduct {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;
    color: black;
    font-weight: bold;
}

.divProductItems {
    margin-top: 2px;
    width: 100%;
    height: 180px;
    min-height: 180px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.productItems {
    width: 100%;
    min-height: 180px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    /* text-decoration: none; */
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.buttonDel {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: filter 0.2s;
}

.productButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: filter 0.2s;
}

.productButton:hover {
    filter: brightness(90%);
}

.portionSize {
    font-size: smaller;
    width: 100%;
    min-height: 20px;
    color: black;
}

.root {
    /* display: flex;
    flex-direction: column; */
    background-color: white;
    width: 95%;
    margin-top: 5px;
    justify-content: space-between;
    min-height: max-content;
    align-items: center;
    border-radius: 4px;
}

.rootArea {
    display: flex;
    min-height: max-content;
}

.media {
    align-self: flex-start;
    min-width: 100%;
    height: 140px;
    min-height: max-content;
    /* border-radius: 50%; */
}

.mediaEmpty {
    flex-direction: row;
    align-items: center;
    max-width: 30%;
    width: 30%;
    height: 50px;
    /* border-radius: 50%; */
}

.contentProd {
    min-width: 90%;
    min-height: max-content;
}

.actionsProd {
    flex-direction: row;
}

.price {
    color: green;
    font-size: large;
    width: 50%;
    text-align: start;
    font-weight: 700;
    flex: 1;
}
