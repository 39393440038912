@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #f0f0f5;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea {
    font: 400 18px Roboto, sans-serif;
}

button {
    cursor: pointer;
}

form input {
    width: 100%;
    height: 40px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin-top: 5px;
}

.button {
    width: 100%;
    height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.button:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.backlink {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #41414d;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.backlink svg {
    margin-right: 8px;
}

.backlink:hover {
    opacity: 0.8;
}


html,
body {
  height: 90%;
}

.imgLogin {
  width: 50%;
}

.login-container {
  width: 90%;
  max-width: 500px;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1 1;
}

.login-container section.form form {
  width: 100%;
  max-width: 350px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.login-container section.form form h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.sectionForm {
  width: 95%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionForm form {
  align-items: center;
}

.cardapioLink {
  display: flex;
  align-items: center;
  color: #41414d;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
  transition: opacity 0.2s;
  justify-content: center;
}

.cardapioLink svg {
  margin-right: 8px;
}

.facaLogin {
  flex: 1 1;
  color: #41414d;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
}

.areaLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.imgRegister {
    width: 30%;
}

.register-container {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.register-container section.form form{
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.register-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.sectionRegister {
    width: 95%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sectionRegister form input {
    font-size: 12px;
}

.buttonNeigh {
    width: 20%;
    height: 40px;
    border: 0.5px;
    border-radius: 8px;
    border-style: solid;
    border-color: #e02041;
    background-color: white;
    color: #FFF;
    font-weight: 700;
    margin-top: 5px;
    margin-left: 3px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.horizontalRegister {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.divActivity {
    align-items: center;
    width: 100%;
    align-content: center;
    display: flex;
    flex-direction: column;
}
.imgRegisterConfirm {
    width: 30%;
}

.registerConfirm-container {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.registerConfirm-container section.form form{
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.registerConfirm-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.sectionRegisterConfirm {
    width: 95%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sectionRegisterConfirm form input {
    font-size: 12px;
}

.buttonNeighConfirm {
    width: 20%;
    height: 40px;
    border: 0.5px;
    border-radius: 8px;
    border-style: solid;
    border-color: #e02041;
    background-color: white;
    color: #FFF;
    font-weight: 700;
    margin-left: 3px;
    margin-top: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.horizontalRegisterConfirm {
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.divActivityConfirm {
    align-items: center;
    width: 100%;
    align-content: center;
    display: flex;
    flex-direction: column;
}
.imgPedido {
    max-width: 60%;
    max-height: 90%;
}

.logoMyRequests {
    width: 30%;
}

.reader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.request-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
}

.containerFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
}

.boddyMyRequests {
    /* background-color: #959595; */
    width: 95%;
    height: 40px;
    border-radius: 8px;
    display: flex;   
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.product {
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 5px;
    font-size: 12px;
}

.containerItem {
    display: flex;
    flex-direction: row;
    width: 30%;
    margin-right: 5px;
    align-items: center;
}

.itemsMyReq {
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.6);
    width: 98%;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    background: white;
    background-color: white;
    border: 0;
    border-radius: 8px;
    border-width: 1px;
    border-style: inherit;
    border-color: #999;
    color: black;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: justify;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    padding-left: 3px;
}



.ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38%;
    height: 85%;
    border: 0;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    color: #FFF;
    background-color: white;
    margin-top: 3px;
    margin-right: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.vert {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.horiz {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.total {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    align-items: flex-start;
    align-content: flex-start;
    text-align: justify;
    width: 100%;
    font-weight: 700;
}

.add {
    height: 50px;
    width: 120px;
    border-radius: 10px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: 5px;
    margin-top: 5px;
    transition: border-color 0.2s; 
    /* position: absolute;  
    top: 50px;
    left: 50px; */
}

.add:hover {
    border-color: #999;
}

.logoRequest {
    width: 20%;
}

.reader {
    /* flex: 1; */
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.request-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
}

.containerFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.boddy {
    background-color: silver ;
    width: 95%;
    height: 40px;
    border-radius: 8px;
    display: flex;   
    flex: 4 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.product {
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 5px;
    font-size: 12px;
}

.containerItem {
    display: flex;
    flex-direction: row;
    width: 30%;
    margin-right: 5px;
    align-items: center;
}

.items {
    width: 98%;
    /* height: 80px; */
    background: #757575;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: justify;
    text-decoration: none;
    /* font-size: 10px; */
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
}

.itemsReCombined {
    width: 98%;
    /* height: 80px; */
    background: coral;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: justify;
    text-decoration: none;
    /* font-size: 10px; */
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
}

.buttonDel {
    width: 100%;
    height: 80px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.vertical {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.horizontal {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    /* padding-right: 2px; */
    padding-left: 5px;
}

.horizontalAdd {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding-right: 5px;
    padding-left: 5px;
}


.total {
    padding-top: 5px;
    align-items: flex-start;
    align-content: flex-start;
    text-align: justify;
    width: 50%;
    font-weight: 700;
    margin-left: 5px;
}

.add {
    height: 40px;
    width: 50%;
    border-radius: 10px;
    border: 1px solid #dcdce6;
    /* background: transparent; */
    background-color: #e02041;
    margin-left: 5px;
    margin-top: 5px;
    transition: border-color 0.2s;
    color: white; 
    font-size: 15px;
}

.add:hover {
    border-color: #999;
}

.buttonRequest {
    width: 100%;
    height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 5px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.buttonRequest:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

html,
body {
  height: 90%;
}

.imgLogin {
  width: 50%;
}

.login-container {
  width: 90%;
  max-width: 500px;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1 1;
}

.login-container section.form form {
  width: 100%;
  max-width: 350px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.login-container section.form form h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.sectionForm {
  width: 95%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionForm form {
  align-items: center;
}

.cardapioLink {
  display: flex;
  align-items: center;
  color: #41414d;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
  transition: opacity 0.2s;
  justify-content: center;
}

.cardapioLink svg {
  margin-right: 8px;
}

.facaLogin {
  flex: 1 1;
  color: #41414d;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
}

.areaLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.logoGroup {
    width: 30%;
}

.groupHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.groupBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: grid;   
    grid-template-columns: 1fr 1fr;
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;

}

.groupProduct {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;    
    color: black;
    font-weight: bold;
}

.divGroupItems {
    display: flex;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: silver;
}

.groupItems {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px;
    margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.buttonDel {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}


.groupButton {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 700;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.groupButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.logoProducts {
    width: 30%;
}

.productHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.productBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    flex: 4 1;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
    overflow: scroll;
}

.productProduct {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;
    color: black;
    font-weight: bold;
}

.divProductItems {
    margin-top: 2px;
    width: 100%;
    height: 180px;
    min-height: 180px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.productItems {
    width: 100%;
    min-height: 180px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    /* text-decoration: none; */
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.buttonDel {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.portionSize {
    font-size: smaller;
    width: 100%;
    min-height: 20px;
    color: black;
}

.root {
    /* display: flex;
    flex-direction: column; */
    background-color: white;
    width: 95%;
    margin-top: 5px;
    justify-content: space-between;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    align-items: center;
    border-radius: 4px;
}

.rootArea {
    display: flex;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
}

.media {
    align-self: flex-start;
    min-width: 100%;
    height: 140px;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    /* border-radius: 50%; */
}

.mediaEmpty {
    flex-direction: row;
    align-items: center;
    max-width: 30%;
    width: 30%;
    height: 50px;
    /* border-radius: 50%; */
}

.contentProd {
    min-width: 90%;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
}

.actionsProd {
    flex-direction: row;
}

.price {
    color: green;
    font-size: large;
    width: 50%;
    text-align: start;
    font-weight: 700;
    flex: 1 1;
}

.logoProductDetail {
    width: 30%;
}

.productDetailHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.form {
    width: 100%;
}

h1 {
    margin-top: 10px;
}

.form input {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin-top: 10px;
    text-align: center;
}

.obs {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin-top: 10px;
    text-align: justify;
    font-size: 12px;
}

.productDetailBoddy {
    background-color: #959595;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;   
    /* grid-template-columns: 1fr 1fr; */
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;

}

.productDetail {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;    
    color: black;
    font-weight: bold;
}

.productDetailItems {
    width: 98%;
    height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    margin-top: 3px;
    margin-left: 3px;
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.decreaseButton {
    width: 49%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    margin-right: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.increaseButton {
    width: 49%;
    height: 50px;
    background: darkgreen;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productDetailButton {
    width: 100%;
    height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productDetailButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.amountDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}
img {
    width: 100%;
    max-width: 250px;
}

.login-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-container section.form form{
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.login-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.sectionForm {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionForm form {
    align-items: center;
}
.imgNeigh {
    width: 30%;
}

.neighHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.neighBoddy {
    background-color: #959595;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;   
    /* grid-template-columns: 1fr 1fr; */
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;

}

.neighItems {
    width: 100%;
    height: 70px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px;
    margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.neighItems p{
    margin-top: 10px;
    margin-bottom: 10px;
}


.neighButton {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 700;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.neighButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.divNeigh {
    margin-top: 2px;
    width: 100%;
    height: 90px;
    min-height: 70px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}
.checkout-container {
    width: 100%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

form {
    min-width: 100%;
}

.checkHorizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
}

.checkHorizontal Switch {
    width: 20%;
}

.checkHorizontal p {
    width: 80%;
}

.lblFormaPgto {
    margin-top: 5px;
}

.selPgto {
    width: 100%;
    margin-right: 10px;
    height: 40px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin-top: 10px;
    border-bottom-style: none;
    border-bottom: 0ch;
    border-bottom-color: white;
}
.logoCheckout {
    width: 30%;
}

.sectionCheckout {
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sectionCheckout input {
    font-size: small;
}


.sectionCheckout h4 {
    width: 100%;
    text-align: right;
    margin-top: 5px;
}

.totalCheckout {
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.6);
    width: 98%;
    height: 100px;
    /* background: white;
    background-color: white; */
    border: 0;
    border-radius: 8px;
    border-width: 1px;
    border-style: outset;
    border-color: #999;
    color: black;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: justify;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    padding-left: 3px;
    padding-right: 5px;
}

.buttonNeighCheckout {
    width: 20%;
    height: 40px;
    border: 0.5px;
    border-radius: 8px;
    border-style: solid;
    border-color: #e02041;
    background-color: white;
    color: #FFF;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 3px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.horizontalCheckout {
    display: flex;
    flex-direction: row;
    align-items: center;
}
img {
    width: 100%;
    max-width: 250px;
}

.login-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-container section.form form{
    width: 100%;
    max-width: 350px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.login-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.sectionForm {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionForm form {
    align-items: center;
}
.logoCombine {
    width: 20%;
}

.combine-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
    padding: 2px;
}

.combineHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.combineBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    flex: 4 1;
    flex-direction: column;
    align-content: center;
    align-items: center;
    overflow: scroll;
    /* margin-top: 5px;
    background-color: silver;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    display: flex;   
    flex: 3;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
    align-items: center; */
}

.divCombItem {
    margin-top: 2px;
    width: 100%;
    height: 60px;
    min-height: 60px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.combItems {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.combineFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.combineButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.combineButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.combinedArea {
    margin-top: 5px;
    background-color: antiquewhite;
    width: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: #959595;
    border-width: 1px;
    padding: 2px;
}

.orientation {
    background-color: antiquewhite;
    margin-top: 3px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    text-align: center;   
}

.portionComb{
    font-size: x-small;
    font-style: italic;
    max-height: 30px;
    margin-right: 3px;
    margin-left: 3px;
    width: 95%;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
    padding: 2px;
    background-color: #f0f0f5;
    text-align: center;
    resize: none;
}
.orderHorizontal {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}

.portionSize {
    font-size: smaller;
    width: 100%;
    min-height: 20px;
    color: black;
}

/* .root {
    max-width: 90%;
    min-height: 350px;
    margin-top: 10px;
} */

/* .rootArea {
    min-height: 300px;
    max-height: 300px;
} */

/* .media {
    align-self: flex-start;
    min-width: 100%;
    width: 100%;
} */

/* .contentProd {
    min-width: 90%;
    min-height: 200px;
} */

/* .actionsProd {
    flex-direction: row;
} */

/* .price {
    color: green;
    font-size: large;
    width: 50%;
    text-align: start;
    font-weight: 700;
} */

.logoEdge {
    width: 20%;
}

.edge-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
    padding: 2px;
}


.edgeHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.edgeBoddy {
    margin-top: 5px;
    background-color: silver;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    display: flex;   
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.divEdgeItem {
    margin-top: 2px;
    width: 100%;
    height: 60px;
    min-height: 60px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.edgeItems {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.edgeFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.edgeButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.edgeButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.edgeArea {
    margin-top: 5px;
    background-color: antiquewhite;
    width: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: #959595;
    border-width: 1px;
    padding: 2px;
}

.orientation {
    background-color: antiquewhite;
    margin-top: 3px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    text-align: center;   
}
.logoEdge {
    width: 20%;
}

.edge-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
    padding: 2px;
}


.edgeHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.edgeBoddy {
    margin-top: 5px;
    background-color: silver;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    display: flex;   
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.divEdgeItem {
    margin-top: 2px;
    width: 100%;
    height: 60px;
    min-height: 60px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.edgeItems {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.edgeFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.edgeButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.edgeButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.edgeArea {
    margin-top: 5px;
    background-color: antiquewhite;
    width: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: #959595;
    border-width: 1px;
    padding: 2px;
}

.orientation {
    background-color: antiquewhite;
    margin-top: 3px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    text-align: center;   
}
.combCheckLogo {
    width: 20%;
}

.combCheck-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
    padding: 2px;
}


.combCheckHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.combCheckBoddy {
    margin-top: 5px;
    background-color: silver;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    display: flex;   
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.divItemCheckout {
    /* margin-top: 2px; */
    width: 100%;
    min-height: 80px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.buttonItemCheckout {  
    width: 100%;
    min-height: 80px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    text-align: justify;
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    padding-top: 3px;
}

.combCheckFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.combCheckButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 5px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.combCheckButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.edgeArea {
    margin-top: 5px;
    background-color: antiquewhite;
    width: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: #959595;
    border-width: 1px;
    padding: 2px;
}

.orientation {
    background-color: antiquewhite;
    margin-top: 1px;
    margin-bottom: 3px;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    text-align: center;   
}

.divHorizontal {
    display: flex;
    flex-direction: row;
}
.combCheckLogo {
    width: 20%;
}

.combCheck-container {
    width: 90%;
    max-width: 500px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1;
    padding: 2px;
}


.combCheckHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.combCheckBoddy {
    margin-top: 5px;
    background-color: silver;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    display: flex;   
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;
}

.divItemCheckout {
    /* margin-top: 2px; */
    width: 100%;
    min-height: 80px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.buttonItemCheckout {  
    width: 100%;
    min-height: 80px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    text-align: justify;
    text-decoration: none;
    align-items: center;
    padding-left: 10px;
    padding-top: 3px;
}

.combCheckFooter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.combCheckButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 300;
    margin-top: 5px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.combCheckButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.edgeArea {
    margin-top: 5px;
    background-color: antiquewhite;
    width: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: #959595;
    border-width: 1px;
    padding: 2px;
}

.orientation {
    background-color: antiquewhite;
    margin-top: 1px;
    margin-bottom: 3px;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    text-align: center;   
}

.divHorizontal {
    display: flex;
    flex-direction: row;
}
.logoGroup {
    width: 30%;
}

.groupHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.groupBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: grid;   
    grid-template-columns: 1fr 1fr;
    flex: 3 1;
    flex-direction: column;
    align-content: flex-start;
    overflow: scroll;

}

.groupProduct {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;    
    color: black;
    font-weight: bold;
}

.divGroupItems {
    display: flex;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: silver;
}

.groupItems {
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px;
    margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    text-decoration: none;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.buttonDel {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}


.groupButton {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #FFF;
    font-weight: 700;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.groupButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.logoProducts {
    width: 30%;
}

.productHeader {
    flex: 1 1;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.productBoddy {
    background-color: silver;
    width: 95%;
    max-width: 500px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    flex: 4 1;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
    overflow: scroll;
}

.productProduct {
    margin-left: 10px;
    margin-right: 15px;
    font-size: 12px;
    width: 100%;
    color: black;
    font-weight: bold;
}

.divProductItems {
    margin-top: 2px;
    width: 100%;
    height: 180px;
    min-height: 180px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: silver;
}

.productItems {
    width: 100%;
    min-height: 180px;
    background: #f0f0f5;
    border: 0;
    border-radius: 8px;
    color: black;
    /* margin-top: 3px; */
    /*margin-left: 3px; */
    /* margin-right: 3px; */
    text-align: center;
    /* text-decoration: none; */
    /* display: flex;
    flex-direction: row; */
    align-items: center;
}

.buttonDel {
    width: 100%;
    height: 50px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    margin-top: 3px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productButton {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 300;
    margin-top: 12px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.productButton:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.portionSize {
    font-size: smaller;
    width: 100%;
    min-height: 20px;
    color: black;
}

.root {
    /* display: flex;
    flex-direction: column; */
    background-color: white;
    width: 95%;
    margin-top: 5px;
    justify-content: space-between;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    align-items: center;
    border-radius: 4px;
}

.rootArea {
    display: flex;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
}

.media {
    align-self: flex-start;
    min-width: 100%;
    height: 140px;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    /* border-radius: 50%; */
}

.mediaEmpty {
    flex-direction: row;
    align-items: center;
    max-width: 30%;
    width: 30%;
    height: 50px;
    /* border-radius: 50%; */
}

.contentProd {
    min-width: 90%;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
}

.actionsProd {
    flex-direction: row;
}

.price {
    color: green;
    font-size: large;
    width: 50%;
    text-align: start;
    font-weight: 700;
    flex: 1 1;
}

